<template>
    <div v-if="cityData" class="city-data-container">
        <h2 class="city-data-header">{{ cityData.name }}</h2>
        <div class="city-data-grid">
            <div class="city-data-card" @mouseover="animateCard" @mouseleave="resetAnimation">
                <i class="fas fa-users city-data-card-icon"></i>
                <h3 class="city-data-card-title">Population</h3>
                <p class="city-data-card-value">{{ cityData.population }}</p>
            </div>
            <div v-for="(value, key) in cityData.weather" :key="key" class="city-data-card" 
                 @mouseover="animateCard" @mouseleave="resetAnimation">
                <i :class="getWeatherIcon(key)" class="city-data-card-icon"></i>
                <h3 class="city-data-card-title">{{ key }}</h3>
                <p class="city-data-card-value">{{ value }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "CityHome",
    methods: {
        animateCard(event) {
            event.target.classList.add('animate');
        },
        resetAnimation(event) {
            event.target.classList.remove('animate');
        },
        getWeatherIcon(key) {
            switch(key) {
                case 'temperature': return 'fas fa-thermometer-half';
                case 'wind': return 'fas fa-wind'; // Blowing wind lines icon for wind data
                case 'humidity': return 'fas fa-tint';
                // Add more conditions as necessary for other weather data types
                default: return 'fas fa-cloud'; // Default to cloud icon
            }
        }
    },
    computed: mapGetters({
        cityData: "city/cityData"
    }),
}
</script>
[3:33 AM]
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css');

.city-data-container {
    font-family: 'Roboto', sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    background: linear-gradient(135deg, #f8f9fa, #e6e9ec);
    border-radius: 10px;
    box-shadow: 0px 4px 14px rgba(0,0,0,0.1);
    transition: all .3s ease-in-out;
}

.city-data-header {
    text-align: center;
    margin-bottom: 30px;
    font-size: 28px;
    color: #333;
    font-weight: 700;
}

.city-data-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 30px;
}

.city-data-card {
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 14px rgba(0,0,0,0.1);
    transition: all .3s ease-in-out;
}

.city-data-card-icon {
    font-size: 24px;
    color: #aaa;
    margin-bottom: 15px;
    display: block;
    text-align: center;
}

.city-data-card.animate {
    transform: scale(1.05);
    box-shadow: 0px 6px 20px rgba(0,0,0,0.1);
}

.city-data-card-title {
    margin-bottom: 15px;
    color: #888;
    text-transform: capitalize;
    font-weight: 400;
}

.city-data-card-value {
    font-size: 20px;
    color: #333;
    font-weight: 300;
}
</style>